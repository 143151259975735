import MoveType from '@gamepark/prehistories/moves/MoveType'
import MoveView from '@gamepark/prehistories/moves/MoveView'
import {Action, Rules, Undo} from '@gamepark/rules-api'
import {playHuntCardInView} from '@gamepark/prehistories/moves/PlayHuntCard'
import SetCaveDisplayed, {setCaveDisplayed} from './localMoves/setCaveDisplayed'
import {revealHuntCardsInView} from '@gamepark/prehistories/moves/RevealHuntCards'
import {placeTile} from '@gamepark/prehistories/moves/PlaceTile'
import {spendHunter} from '@gamepark/prehistories/moves/SpendHunter'
import {validateSpentHunters} from '@gamepark/prehistories/moves/ValidateSpentHunters'
import {fulfillObjective, fulfillObjectiveMove} from '@gamepark/prehistories/moves/FulfillObjective'
import {refillHuntingBoardInView} from '@gamepark/prehistories/moves/RefillHuntingBoard'
import {endTurn} from '@gamepark/prehistories/moves/EndTurn'
import {takeBackPlayedCardsInView} from '@gamepark/prehistories/moves/TakeBackPlayedCards'
import {drawCardsInView} from '@gamepark/prehistories/moves/DrawCards'
import {shuffleDiscardPileInView} from '@gamepark/prehistories/moves/ShuffleDiscardPile'
import SetSelectedHunters, {resetSelectedHunters, ResetSelectedHunters, setSelectedHunters} from './localMoves/setSelectedHunters'
import PlayerColor from '@gamepark/prehistories/PlayerColor'
import canUndo from '@gamepark/prehistories/canUndo'
import GameLocalView from './GameLocalView'
import {getHuntingPlayer} from '@gamepark/prehistories/types/HuntingPlayer'
import getBoardZones from '@gamepark/prehistories/material/BoardZones'
import {getFulfilledObjectives} from '@gamepark/prehistories/material/ObjectiveRules'
import {isWinner} from '@gamepark/prehistories/Prehistories'

type LocalMove = MoveView | SetCaveDisplayed | SetSelectedHunters | ResetSelectedHunters

export default class PrehistoriesView extends Rules<GameLocalView, MoveView> implements Undo<GameLocalView, MoveView, PlayerColor> {

  isTurnToPlay(playerId: PlayerColor): boolean {
    if(this.state.players.some(isWinner)) return false
    const huntingPlayer = getHuntingPlayer(this.state)
    if (!huntingPlayer) {
      return !this.state.players.find(p => p.color === playerId)?.isReady
    } else {
      return huntingPlayer.color === playerId
    }
  }

  getAutomaticMoves(): MoveView[] {
    const huntingPlayer = getHuntingPlayer(this.state)
    if (!huntingPlayer) return []
    if (huntingPlayer.isReady) {
      if (huntingPlayer.played.length !== 0) {
        return [{type: MoveType.TakeBackPlayedCards}]
      }
    } else if (huntingPlayer.hunting.hunt) {
      if (getBoardZones(this.state.players.length)[huntingPlayer.hunting.hunt.zone].safe <= huntingPlayer.hunting.hunt.huntersValue) {
        return [{type: MoveType.ValidateSpentHunters}]
      }
    } else if (huntingPlayer.hunting.tilesHunted > 0) {
      return [...getFulfilledObjectives(this.state).map(fulfillObjectiveMove)]
    }
    return []
  }

  play(move: LocalMove): MoveView[] {
    switch (move.type) {
      case MoveType.PlayHuntCard:
        playHuntCardInView(this.state, move)
        break
      case MoveType.RevealHuntCards:
        revealHuntCardsInView(this.state, move)
        break
      case MoveType.PlaceTile:
        placeTile(this.state, move)
        break
      case MoveType.SpendHunter:
        spendHunter(this.state, move)
        break
      case MoveType.ValidateSpentHunters:
        validateSpentHunters(this.state)
        break
      case MoveType.FulfillObjective:
        fulfillObjective(this.state, move)
        break
      case MoveType.EndTurn:
        endTurn(this.state, move)
        break
      case MoveType.TakeBackPlayedCards:
        takeBackPlayedCardsInView(this.state)
        break
      case MoveType.DrawCards:
        drawCardsInView(this.state, move)
        break
      case MoveType.ShuffleDiscardPile:
        shuffleDiscardPileInView(this.state, move)
        break
      case MoveType.RefillHuntingBoard:
        refillHuntingBoardInView(this.state, move)
        break
      case 'SetCaveDisplayed':
        setCaveDisplayed(this.state, move)
        break
      case 'SetSelectedHunters':
        setSelectedHunters(this.state, move)
        break
      case 'ResetSelectedHunters':
        resetSelectedHunters(this.state)
        break
    }
    return []
  }

  canUndo(action: Action<MoveView, PlayerColor>, consecutiveActions: Action<MoveView, PlayerColor>[]): boolean {
    return canUndo(action, consecutiveActions)
  }

  restoreLocalMoves(localState: GameLocalView) {
    this.state.caveDisplayed = localState.caveDisplayed
  }
}